 <template>
    <div style="display: inline;"
         :title="title">
        <svg :height="size"
             :width="size"
             :style="{verticalAlign: verticalAlign}">
            <circle :cx="size / 2"
                    :cy="size / 2"
                    :r="size / 3"
                    :fill="color"
                    stroke-width="2"
                    :stroke="borderColor"
                    v-if="borderColor"/>
            <circle :cx="size / 2"
                    :cy="size / 2"
                    :r="size / 3"
                    :fill="color"
                    v-else/>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'SvgCircle',
        props: {
            title: {
                required: false,
                type: String,
                default: ''
            },
            color: {
                required: true,
                type: String
            },
            size: {
                required: false,
                type: Number,
                default: 25
            },
            verticalAlign: {
                required: false,
                type: String,
                default: 'middle'
            },
            borderColor: {
                type: String,
                required: false
            }
        }
    };
</script>