<template>
    <SvgCircle :size="size"
               :title="statusTitle"
               :color="getStatusColorr()"
               :verticalAlign="verticalAlign"
               :borderColor="borderColor" />
</template>

<script>
    import SvgCircle from '@/components/SvgCircle';
    import {
        getStatusColor,
        getStatusName
    } from '@/services/control-position-status.service';

    export default {
        name: 'ControlPositionStatusBall',
        components: {
            SvgCircle
        },
        computed: {
            statusTitle() {
                return !this.title ? this.getStatusNamee() : this.title
            }
        },
        props: {
            title: {
                required: false,
                type: String,
                default: ''
            },
            statusId: Number,
            size: {
                required: false,
                type: Number,
                default: 25
            },
            verticalAlign: {
                required: false,
                type: String,
                default: 'middle'
            },
            borderColor: {
                type: String,
                required: false,
                default: function() {
                    if (this.statusId === 15)
                        return getStatusColor(1);
                    return null;
                }
            }
        },
        methods: {
            getStatusColorr() {
                if (!this.statusId)
                    return '';
                const color = getStatusColor(this.statusId);
                return color;
            },
            getStatusNamee() {
                if (!this.statusId)
                    return '';
                const name = getStatusName(this.statusId);
                return name;
            }
        }
    };
</script>