<template>
  <div
    class="spinner"
    :style="
      'margin:' +
      margin +
      'px auto;width:' +
      width +
      'px;height:' +
      height +
      'px;'
    "
  >
    <div :style="'width:' + width / 10 + 'px'" class="rect1" />
    <div :style="'width:' + width / 10 + 'px'" class="rect2" />
    <div :style="'width:' + width / 10 + 'px'" class="rect3" />
    <div :style="'width:' + width / 10 + 'px'" class="rect4" />
    <div :style="'width:' + width / 10 + 'px'" class="rect5" />
    <span v-if="text != ''">{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: false,
      default: ''
    },
    width: {
      type: Number,
      default: 50
    },
    height: {
      type: Number,
      default: 40
    },
    margin: {
      type: Number,
      default: 100
    }
  }
};
</script>

<style scoped>
.spinner {
  text-align: center;
  font-size: 10px;
  position: relative;
}

.spinner > div {
  background-color: #333;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
  margin-right: 1px;
}

.spinner > span {
  white-space: nowrap;
  font-size: 2.5rem;
  position: absolute;
  bottom: -4rem;
  left: 50%;
  transform: translateX(-50%);
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
</style>
